<template>
  <div
    v-if="!loading && !exception"
    class="comparison">
    <PageBanner
      background="alternative"
      :heading="content.heading"
      :subheading="content.subheading" />

    <PageBody>
      <article class="comparison__info comparison__info--half">
        <h3 class="comparison__label">
          {{ content.descriptionLabel }}
        </h3>
        <p
          class="comparison__description"
          v-html="content.description" />
      </article>

      <article class="comparison__info">
        <h3 class="comparison__label">
          {{ content.infoLabel }}
        </h3>

        <ContentCards :offers="content.broadbandSpeedDescription" />
      </article>
    </PageBody>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import PageBanner from '@/components/views/PageBanner'
import PageBody from '@/components/views/PageBody'
import ContentCards from '@/components/broadband-comparison/ContentCards'

export default {
  components: {
    PageBanner,
    PageBody,
    ContentCards
  },
  computed: {
    ...mapState('BroadbandComparison', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('BroadbandComparison', ['loadContent'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/typeface.scss";

.comparison {
  &__info {
    padding-bottom: 1rem;

    @media(min-width: $breakpoint-md) {
      &--half {
        width: 50%;
      }
    }
  }
}
</style>
