<template>
  <div class="cards">
    <div
      v-for="(offer, key) of offers"
      :key="key"
      class="cards__card">
      <h4 class="cards__card-title teasers-intro">
        {{ offer.title }}
      </h4>
      <div
        class="cards__card-content"
        v-html="offer.content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offers: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/typeface.scss";
@import "@/assets/scss/main.scss";
@import "@/assets/scss/variables.scss";

.cards {
  @media(min-width: $breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    @include default-shadow;
    background-color: $grey-200;
    margin: 2rem 0;
    padding: 26px;
    letter-spacing: 0.5px;

    @media(min-width: $breakpoint-md) {
      margin: 12px 0;
      padding: 2rem;
      width: calc(100% / 3 - 16px);

      &:not(:nth-child(3n)) {
        margin-right: 24px;
      }
    }
  }

  &__card-title {
    margin-top: 5px;
    font-weight: 700;
    text-align: center;
  }

  &__card-content {
    margin-top: 30px;
    font: 400 1rem Roboto;
    line-height: 22px;
  }
}
</style>
